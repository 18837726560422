import { Injectable } from '@angular/core';
import { datadogRum } from '@datadog/browser-rum'

@Injectable({
  providedIn: 'root'
})
export class DataDogService {

  init() {
    datadogRum.init({
      applicationId: 'b0ccb7ac-0b9d-44b9-8168-8692c452ddeb',
      clientToken: 'pubb3a10960d2572a6ae9a1daa06615f2be',
      // `site` refers to the Datadog site parameter of your organization
      // see https://docs.datadoghq.com/getting_started/site/
      site: 'datadoghq.eu',
      service: 'admin-user-access',
      env: 'stag',
      // Specify a version number to identify the deployed version of your application in Datadog
      // version: '1.0.0',
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      defaultPrivacyLevel: 'mask-user-input',
  });
  }
}
