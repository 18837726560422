import { Injectable } from '@angular/core';
import { DATE_FORMAT } from '../const/date-format';
import { DATETIME_FORMAT } from '../const/datetime-format';

@Injectable({
  providedIn: 'root'
})
export class I18NService {

  private _messages: { [key: string]: { [key: string]: string; } } = {};

  constructor() { }

  get messages(): { [key: string]: { [key: string]: string; } } {
    return this._messages;
  }

  set messages(messages: { [key: string]: { [key: string]: string; } }) {
    this._messages = messages;
  }

  getLocale(languages: string[], def: string, language: string): string {
    let lang = def;
    for (let i = 0; i < languages.length; i++) {
      if (languages[i] === language) {
        lang = language;
        break;
      }
    }
    return lang;
  }

  getFormattedMessage(msg: string, values?: any[]): string {
    if (values && values.length > 0) {
      for (let i = 0, len = values.length; i < len; i++) {
        msg = msg.replace('{' + i + '}', values[i]);
      }
    }
    return msg;
  }

  extend(obj1: any, obj2: any): any {
    let result: any = JSON.parse(JSON.stringify(obj1));
    const keys = Object.keys(obj2);
    for (let key of keys) {
      const val = obj2[key];
      if (typeof val === 'string') {
        result[key] = val;
      } else {
        result[key] = this.extend(result[key] || {}, val);
      }
    }
    return result;
  }

  formatDate(locale: string, time: number | undefined): string {
    if (time === undefined) {
      return '';
    }
    return this.formatTime(time, DATE_FORMAT[locale]);
  }

  formatDateTime(locale: string, time: number): string {
    return this.formatTime(time, DATETIME_FORMAT[locale]);
  }

  formatDateDefault(time: number | undefined): string {
    if (time === undefined) {
      return '';
    }
    return this.formatTime(time, 'yyyy-MM-dd');
  }
  
  formatDateTimeDefault(time: number): string {
    return this.formatTime(time, 'yyyy-MM-dd HH:mm:ss');
  }

  getMonthNames(msg: { [key: string]: string }): string[] {
    return [msg['January'],
    msg["February"],
    msg["March"],
    msg["April"],
    msg["May"],
    msg["June"],
    msg["July"],
    msg["August"],
    msg["September"],
    msg["October"],
    msg["November"],
    msg["December"]];
  }

  getMonthShortNames(msg: { [key: string]: string }): string[] {
    return [msg['shortNameJanuary'],
    msg["shortNameFebruary"],
    msg["shortNameMarch"],
    msg["shortNameApril"],
    msg["shortNameMay"],
    msg["shortNameJune"],
    msg["shortNameJuly"],
    msg["shortNameAugust"],
    msg["shortNameSeptember"],
    msg["shortNameOctober"],
    msg["shortNameNovember"],
    msg["shortNameDecember"]];
  }

  getDayShortNameTwoLetter(msg: { [key: string]: string }): string[] {
    return [msg['shortDayNameTwoLetterSunday'],
    msg['shortDayNameTwoLetterMonday'],
    msg['shortDayNameTwoLetterTuesday'],
    msg['shortDayNameTwoLetterWednesday'],
    msg['shortDayNameTwoLetterThursday'],
    msg['shortDayNameTwoLetterFriday'],
    msg['shortDayNameTwoLetterSaturday']
    ];
  }

  isEmpty(obj: any): boolean {
    return Object.keys(obj).length === 0 && obj.constructor === Object;
  }

  private joinObj(obj: { [key: string]: number }, separator: string): string {
    let out = [];
    for (let k in obj) {
      out.push(k);
    }
    return out.join(separator);
  }

  private formatTime(time: number, format: string): string {
    const date = new Date(time);
    const dateObject: { [key: string]: number } = {
      M: date.getMonth() + 1,
      d: date.getDate(),
      D: date.getDate(),
      h: date.getHours(),
      H: date.getHours(),
      m: date.getMinutes(),
      s: date.getSeconds(),
      y: date.getFullYear(),
      Y: date.getFullYear()
    };
    const dateMatchRegex = this.joinObj(dateObject, "+|") + "+";
    const regEx = new RegExp(dateMatchRegex, "g");

    return format.replace(regEx, function (formatToken) {
      const datePartValue = dateObject[formatToken.slice(-1)].toString();
      let tokenLength = formatToken.length;
      if (formatToken.indexOf('y') < 0 && formatToken.indexOf('Y') < 0) {
        tokenLength = Math.max(formatToken.length, datePartValue.length);
      }
      const zeroPad = (datePartValue.length < formatToken.length ? "0".repeat(tokenLength) : "");
      return (zeroPad + datePartValue).slice(-tokenLength);
    });
  }
}
